import styled, { css } from 'styled-components';
import { WIDTH } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';

const AppWrapper = styled.div<{ $isSideBarVisible: boolean }>`
  ${({ theme: { convertVW }, $isSideBarVisible }) => css`
    padding-left: ${$isSideBarVisible ? convertVW(WIDTH.sidebar) : 0};
    transition: padding-left 0.5s;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      padding-left: 0;
    }
  `}
`;

export { AppWrapper as Wrapper };
