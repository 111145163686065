import styled, { css } from 'styled-components';

const ButtonWrapper = styled.div<{
  $isDisabled: boolean;
  $isRounded: boolean;
  $isOutline: boolean;
  $bgColor?: string;
}>`
  ${({
    theme: { convertVW },
    $isDisabled,
    $isRounded,
    $isOutline,
    $bgColor,
  }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(12)};
    height: ${convertVW(40)};
    width: ${$isRounded ? convertVW(40) : '100%'};
    padding: 0 ${$isRounded ? convertVW(12) : convertVW(16)};
    border-radius: ${$isRounded ? '50%' : convertVW(8948.69)};
    background: ${$bgColor
      ? $bgColor
      : $isDisabled
      ? 'gray'
      : $isOutline
      ? 'transparent'
      : '#2c2c2c'};
    border: ${convertVW(1)} solid ${$isDisabled ? 'gray' : '#2c2c2c'};
    cursor: ${$isDisabled ? 'not-allowed' : 'pointer'};

    white-space: nowrap;
    user-select: none;

    &:hover {
      text-decoration: ${$isOutline ? 'underline' : 'none'};
    }
  `}
`;

const ButtonText = styled.div<{ $isOutline: boolean }>`
  ${({ theme: { convertVW }, $isOutline }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-weight: 600;
    font-size: ${convertVW(12)};
    line-height: ${convertVW(13)};
    color: ${$isOutline ? '#2c2c2c' : '#ffffff'};
  `}
`;

const ButtonIcon = styled.img`
  ${({ theme: { convertVW } }) => css`
    max-width: ${convertVW(16)};
  `}
`;

export { ButtonWrapper as Wrapper, ButtonText as Text, ButtonIcon as Icon };
