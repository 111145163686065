/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Images */
import BurgerMenuIcon from 'assets/images/svg/hamburger-menu.svg';
import ProfileIcon from 'assets/images/svg/profile-icon.svg';
import ArrowIcon from 'assets/images/svg/arrow-down-icon.svg';

/** Hooks */
import { useDeviceDetect } from 'hooks/UseDeviceDetect';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useAppSelector } from 'hooks/UseAppSelector';

/** Features */
import Profile from 'features/Profile/Profile';
import Tags from 'features/Tags/Tags';
import PaginationButton from 'features/PaginationButton/PaginationButton';
import SortButton from 'features/SortButton/SortButton';

/** Store */
import { setSidebarVisibility } from 'store/slices/Sidebar.slice';
import {
  selectCopyCount,
  selectHeaderTitle,
  selectIsBottomSideDisabled,
  selectIsCopyCountRequestTriggered,
  selectIsHeaderVisible,
  setCopyCount,
  setHeaderVisibility,
  setIsCopyCountRequestTriggered,
  setSelectedFilterIndustries,
  setSelectedFilterSubjects,
} from 'store/slices/Header.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Types */
import { ITag } from 'features/Header/types';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Api */
import { getRequest } from 'api/apiClient';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Styles */
import * as S from './Header.styled';

const Header: FC = () => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const { user, isUserDesigner, isUserDefault, plans } = useAuth();

  const isHeaderVisible = useAppSelector(selectIsHeaderVisible);
  const isBottomSideDisabled = useAppSelector(selectIsBottomSideDisabled);
  const copyCount = useAppSelector(selectCopyCount);
  const isCopyCountRequestTriggered = useAppSelector(
    selectIsCopyCountRequestTriggered
  );

  const selectedPlan = plans.find(
    (plan) => plan.Uid === user?.Account?.CurrentSubscription?.Plan?.Uid
  );

  const isPro = selectedPlan?.Name === 'Pro';

  const [isBottomSideVisible, setIsBottomSideVisible] = useState<boolean>(true);
  const [subjects, setSubjects] = useState<ITag[]>([]);
  const [industries, setIndustries] = useState<ITag[]>([]);
  const [designerUser, setDesignerUser] = useState<any>();

  const title = useAppSelector(selectHeaderTitle);

  const getSubjects = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SUB_CATEGORIES);

    setSubjects(flattenResponse(response.data.data));
  };

  const getIndustries = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.INDUSTRIES);

    setIndustries(flattenResponse(response.data.data));
  };

  const getDesignerUser = async (): Promise<void> => {
    const response = await getRequest(
      ENDPOINTS.DESIGNER_USER_WITH_UID(user.Uid)
    );

    setDesignerUser(flattenResponse(response.data.data)[0]);
  };

  const getSystemUserCopyCount = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SYSTEM_USER_WITH_UID(user.Uid));

    dispatch(setCopyCount(flattenResponse(response.data.data)[0]?.copyCount));
    dispatch(setIsCopyCountRequestTriggered(false));
  };

  useEffect(() => {
    void getSubjects();
    void getIndustries();
  }, []);

  useEffect(() => {
    isUserDesigner && void getDesignerUser();
  }, [isUserDesigner]);

  useEffect(() => {
    isUserDefault && dispatch(setIsCopyCountRequestTriggered(true));
  }, [isUserDefault]);

  useEffect(() => {
    isCopyCountRequestTriggered && getSystemUserCopyCount();
  }, [isCopyCountRequestTriggered]);

  useEffect(() => {
    dispatch(setHeaderVisibility(!!user));
  }, [user]);

  return (
    <S.Wrapper
      $isBottomSideDisabled={isBottomSideDisabled}
      $isBottomSideVisible={isBottomSideVisible}
      $isVisible={isHeaderVisible}
    >
      <S.TopSide
        $isBottomSideDisabled={isBottomSideDisabled}
        $isBottomSideVisible={isBottomSideVisible}
      >
        <S.PageNameWrapper>
          {isMobile && (
            <S.BurgerMenuWrapper
              onClick={() => dispatch(setSidebarVisibility(true))}
            >
              <S.BurgerMenu src={BurgerMenuIcon} alt={'Burger Menu'} />
            </S.BurgerMenuWrapper>
          )}
          <S.PageName>{title}</S.PageName>
        </S.PageNameWrapper>
        {isUserDesigner && (
          <S.TotalBalance>${designerUser?.totalBalance ?? 0}</S.TotalBalance>
        )}
        {isUserDefault && !isPro && (
          <S.CopyLimit>Copy limit: {copyCount}</S.CopyLimit>
        )}
        <Profile image={ProfileIcon} />
      </S.TopSide>
      <S.BottomSide $isBottomSideDisabled={isBottomSideDisabled}>
        <S.TagsContainer>
          <Tags
            title={'filter by subject'}
            tags={subjects}
            onSelect={(tags) => dispatch(setSelectedFilterSubjects(tags))}
          />
          <Tags
            title={'filter by industry'}
            tags={industries}
            onSelect={(tags) => dispatch(setSelectedFilterIndustries(tags))}
          />
        </S.TagsContainer>
        <S.ButtonsContainer>
          {!isMobile && <PaginationButton />}
          <SortButton />
        </S.ButtonsContainer>
        <S.ShrinkButton
          onClick={() => setIsBottomSideVisible(!isBottomSideVisible)}
        >
          <S.ShrinkArrowIcon
            $isBottomSideVisible={isBottomSideVisible}
            src={ArrowIcon}
            alt={'Shrink'}
          />
        </S.ShrinkButton>
      </S.BottomSide>
    </S.Wrapper>
  );
};

export default Header;
