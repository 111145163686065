import styled, { css } from 'styled-components';

const RemoveModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RemoveModalText = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const RemoveModalButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    gap: ${convertVW(24)};
    margin-top: ${convertVW(72)};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`;

export {
  RemoveModalWrapper as Wrapper,
  RemoveModalText as Text,
  RemoveModalButtonsContainer as ButtonsContainer,
};
