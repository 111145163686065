/** Dependencies */
import React, { FC } from 'react';

/** Components */
import Button from 'components/Button/Button';

/** Styles */
import * as S from './RemovalModalContent.styled';

interface IProps {
  text: string;
  onCancel: () => void;
  onConfirm: () => void;
}

const RemovalModalContent: FC<IProps> = ({ text, onCancel, onConfirm }) => {
  return (
    <S.Wrapper>
      <S.Text>{text}</S.Text>
      <S.ButtonsContainer>
        <Button onClick={onCancel} text={'Cancel'} />
        <Button onClick={onConfirm} text={'Confirm'} isOutline />
      </S.ButtonsContainer>
    </S.Wrapper>
  );
};

export default RemovalModalContent;
