import styled, { css } from 'styled-components';
import { WIDTH, ZINDEX } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';
import { Link } from 'react-router-dom';

const SidebarWrapper = styled.div<{ $isVisible: boolean }>`
  ${({ theme: { convertVW, sidebar_bg }, $isVisible }) => css`
    position: fixed;
    width: ${convertVW(WIDTH.sidebar)};
    height: 100vh;
    background-color: ${sidebar_bg};
    z-index: ${ZINDEX.sidebar};
    left: ${$isVisible ? 0 : convertVW(-WIDTH.sidebar)};
    transition: left 0.5s;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      box-shadow: ${convertVW(1)} 0 ${convertVW(5)} rgba(0, 0, 0, 0.05);
    }
  `}
`;

const SidebarLogoWrapper = styled(Link)`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${convertVW(199)};
    cursor: pointer;
    padding: ${convertVW(24)};
  `}
`;

const SidebarLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const SidebarCloseButton = styled.img<{ $isSideBarVisible: boolean }>`
  ${({ theme: { convertVW }, $isSideBarVisible }) => css`
    position: absolute;
    height: ${convertVW(24)};
    width: ${convertVW(28)};
    background-color: #f4f6fa;
    top: ${convertVW(48)};
    border-top-left-radius: ${convertVW(30)};
    border-bottom-left-radius: ${convertVW(30)};
    right: 0;
    cursor: pointer;
    transform: rotateY(${$isSideBarVisible ? '0' : '180deg'})
      translateX(${$isSideBarVisible ? '0' : '-100%'});
    transition: transform 0.5s;
  `}
`;

const SidebarTabsWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${convertVW(24)};
    width: 100%;
    margin-top: ${convertVW(8)};
  `}
`;

const SidebarFooter = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    gap: ${convertVW(16)};
    padding: ${convertVW(24)} 0;
  `}
`;

const SidebarToggleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    left: 0;
    gap: ${convertVW(8)};

    &:after {
      content: '';
      width: 30%;
      height: 1px;
      background: white;
    }

    &:before {
      content: '';
      width: 30%;
      height: 1px;
      background: white;
    }
  `}
`;

export {
  SidebarWrapper as Wrapper,
  SidebarLogoWrapper as LogoWrapper,
  SidebarLogo as Logo,
  SidebarCloseButton as CloseButton,
  SidebarTabsWrapper as TabsWrapper,
  SidebarFooter as Footer,
  SidebarToggleContainer as ToggleContainer,
};
