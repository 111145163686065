/** Dependencies */
import React, { FC } from 'react';

/** Styles */
import * as S from './MessageModalContent.styled';

interface IProps {
  message: string;
}

const MessageModalContent: FC<IProps> = ({ message }) => {
  return <S.Wrapper>{message}</S.Wrapper>;
};

export default MessageModalContent;
