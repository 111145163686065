import styled, { css } from 'styled-components';

const UploadToggleContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    gap: ${convertVW(18)};
  `}
`;

const UploadWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: ${convertVW(110)};
    padding: ${convertVW(60)} ${convertVW(110)} ${convertVW(40)}
      ${convertVW(40)};
  `}
`;

const UploadForm = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(36)};
    width: ${convertVW(701)};
  `}
`;

const UploadCardContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(500)};
  `}
`;

export {
  UploadWrapper as Wrapper,
  UploadToggleContainer as ToggleContainer,
  UploadForm as Form,
  UploadCardContainer as CardContainer,
};
