import styled, { css } from 'styled-components';
import { MAX_HEIGHT, MIN_HEIGHT, ZINDEX } from 'constants/Style';
import { BreakPoint } from 'constants/BreakPoint';

const HeaderWrapper = styled.div<{
  $isBottomSideDisabled: boolean;
  $isBottomSideVisible: boolean;
  $isVisible: boolean;
}>`
  ${({
    theme: { convertVW },
    $isBottomSideDisabled,
    $isBottomSideVisible,
    $isVisible,
  }) => css`
    background-color: white;
    position: ${$isVisible ? 'sticky' : 'absolute'};
    top: ${$isVisible ? 0 : '-100%'};
    right: 0;
    padding: ${convertVW(24)} ${convertVW(40)} ${convertVW(8)};
    z-index: ${ZINDEX.header};
    height: ${$isBottomSideDisabled ? convertVW(100) : ''};
    width: 100%;
    box-shadow: 0 0 ${convertVW(10)} 0 rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
    max-height: ${$isBottomSideVisible
      ? convertVW(MAX_HEIGHT.header)
      : convertVW(MIN_HEIGHT.header)};
    overflow: ${$isBottomSideVisible ? 'visible' : 'hidden'};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      left: 0;
      width: 100%;
      max-height: ${$isBottomSideVisible
        ? convertVW(MAX_HEIGHT.header)
        : convertVW(MIN_HEIGHT.header)};
    }
  `}
`;

const HeaderTopSide = styled.div<{
  $isBottomSideVisible: boolean;
  $isBottomSideDisabled: boolean;
}>`
  ${({
    theme: { convertVW },
    $isBottomSideVisible,
    $isBottomSideDisabled,
  }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: ${$isBottomSideVisible && !$isBottomSideDisabled
        ? convertVW(1)
        : 0}
      solid rgb(148, 148, 148);
    transition: ${!$isBottomSideDisabled ? 'all 0.5s' : 'none'};
    margin-bottom: ${convertVW(24)};
    padding-bottom: ${convertVW(16)};
  `}
`;

const HeaderPageNameWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderTotalBalance = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    background: rgba(102, 217, 150, 0.7);
    position: absolute;
    transform: translateX(-50%);
    left: 50%;

    color: white;
    font-weight: 900;
    font-size: ${convertVW(16)};
    padding: ${convertVW(8)} ${convertVW(16)};
    border-radius: ${convertVW(8)};
  `}
`;

const HeaderCopyLimit = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    background: rgba(237, 114, 74, 0.7);
    position: absolute;
    transform: translateX(-50%);
    left: 50%;

    color: white;
    font-weight: 900;
    font-size: ${convertVW(16)};
    padding: ${convertVW(8)} ${convertVW(16)};
    border-radius: ${convertVW(8)};
  `}
`;

const HeaderBurgerMenuWrapper = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${convertVW(48)};
    height: ${convertVW(48)};
    margin-left: -${convertVW(14)};
    margin-right: ${convertVW(8)};
  `}
`;

const HeaderBurgerMenu = styled.img`
  ${({ theme: { convertVW } }) => css`
    width: ${convertVW(24)};
    height: ${convertVW(24)};
  `}
`;

const HeaderPageName = styled.div`
  ${({ theme: { convertVW } }) => css`
    font-family: Plus Jakarta Sans, sans-serif;
    font-size: ${convertVW(24)};
    line-height: ${convertVW(32)};
    font-weight: 400;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      color: #66d996;
      font-size: ${convertVW(20)};
    }
  `}
`;

const HeaderBottomSide = styled.div<{ $isBottomSideDisabled: boolean }>`
  ${({ theme: { convertVW }, $isBottomSideDisabled }) => css`
    display: ${!$isBottomSideDisabled ? 'flex' : 'none'};
    justify-content: space-between;
    align-items: flex-end;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      align-items: flex-start;
      flex-direction: column;
      gap: ${convertVW(16)};
    }
  `}
`;

const HeaderTagsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: ${convertVW(12)};
    width: 75%;

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
    }
  `}
`;

const HeaderButtonsContainer = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: ${convertVW(8)};
    padding-bottom: ${convertVW(8)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      width: 100%;
    }
  `}
`;

const HeaderShrinkButton = styled.div`
  ${({ theme: { convertVW } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    height: ${convertVW(12)};
    width: ${convertVW(48)};
    border-top-left-radius: ${convertVW(4)};
    border-top-right-radius: ${convertVW(4)};
    background-color: #ddd;
    cursor: pointer;
  `}
`;

const HeaderShrinkArrowIcon = styled.img<{ $isBottomSideVisible: boolean }>`
  ${({ $isBottomSideVisible }) => css`
    height: 100%;
    transition: all 0.5s ease;
    transform: rotate(${$isBottomSideVisible ? '180deg' : '0'});
  `}
`;

export {
  HeaderWrapper as Wrapper,
  HeaderTopSide as TopSide,
  HeaderPageNameWrapper as PageNameWrapper,
  HeaderBurgerMenuWrapper as BurgerMenuWrapper,
  HeaderBurgerMenu as BurgerMenu,
  HeaderPageName as PageName,
  HeaderTotalBalance as TotalBalance,
  HeaderCopyLimit as CopyLimit,
  HeaderBottomSide as BottomSide,
  HeaderTagsContainer as TagsContainer,
  HeaderButtonsContainer as ButtonsContainer,
  HeaderShrinkButton as ShrinkButton,
  HeaderShrinkArrowIcon as ShrinkArrowIcon,
};
