/** Dependencies */
import React, { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

/** Types */
import { IFlattenedItem } from 'features/Sidebar/types';

/** Store */
import {
  selectAdminDesignCounts,
  selectDesignerDesignCounts,
  selectThemeIsDark,
} from 'store/slices/Sidebar.slice';
import { setHeaderTitle } from 'store/slices/Header.slice';

/** Hooks */
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useAppSelector } from 'hooks/UseAppSelector';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Styles */
import * as S from './Tab.styled';

interface IProps {
  tab: IFlattenedItem;
  isTabShownToUser: (tab: IFlattenedItem) => boolean;
}

const Tab: FC<IProps> = ({ tab, isTabShownToUser }) => {
  const { user, isUserDesigner, isUserAdmin } = useAuth();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const isDarkTheme = useAppSelector(selectThemeIsDark);
  const adminDesignCounts = useAppSelector(selectAdminDesignCounts);
  const designerDesignCounts = useAppSelector(selectDesignerDesignCounts);

  const {
    approvedDesignsCount,
    disapprovedDesignsCount,
    pendingDesignsCount,
    archivedDesignsCount,
  } = {
    approvedDesignsCount: isUserAdmin
      ? adminDesignCounts.approvedDesignsCount
      : isUserDesigner
      ? designerDesignCounts.approvedDesignsCount
      : '',
    disapprovedDesignsCount: isUserAdmin
      ? adminDesignCounts.disapprovedDesignsCount
      : isUserDesigner
      ? designerDesignCounts.disapprovedDesignsCount
      : '',
    pendingDesignsCount: isUserAdmin
      ? adminDesignCounts.pendingDesignsCount
      : isUserDesigner
      ? designerDesignCounts.pendingDesignsCount
      : '',
    archivedDesignsCount: isUserAdmin
      ? adminDesignCounts.archivedDesignsCount
      : '',
  };

  const returnDesignCount = (text: string): number | string => {
    if (text.includes('Approved')) {
      return approvedDesignsCount;
    } else if (text.includes('Disapproved')) {
      return disapprovedDesignsCount;
    } else if (text.includes('Pending')) {
      return pendingDesignsCount;
    } else if (text.includes('Archive')) {
      return archivedDesignsCount ?? '';
    }
    return '';
  };

  useEffect(() => {
    if (location.pathname.includes('edit'))
      dispatch(setHeaderTitle('Edit Design'));
    if (location.pathname === tab.url) {
      dispatch(setHeaderTitle(tab.text));
    } else {
      tab?.children?.map(
        (child) =>
          location.pathname === child.url &&
          dispatch(setHeaderTitle(child.text))
      );
    }
  }, [location.pathname, user]);

  return (
    <S.Wrapper>
      <S.Content $isActive={location.pathname === tab.url} to={tab.url}>
        <S.Icon
          src={isDarkTheme ? tab.icon?.url : tab.iconDark?.url}
          alt={tab.text}
        />
        {tab.text}
      </S.Content>
      {tab?.children?.map(
        (child, i) =>
          isTabShownToUser(child) && (
            <S.Child
              to={child.isAvailable ? child.url : {}}
              $isActive={location.pathname === child.url}
              key={i}
            >
              <S.ChildText>{child.text}</S.ChildText>
              {!!returnDesignCount(child.text) && (
                <S.Count>{returnDesignCount(child.text)}</S.Count>
              )}
              {!child.isAvailable && <S.ComingSoon>coming soon</S.ComingSoon>}
            </S.Child>
          )
      )}
    </S.Wrapper>
  );
};

export default Tab;
