/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Images */
import LogoImage from 'assets/images/svg/logo.svg';
import DarkLogoImage from 'assets/images/svg/logoDark.svg';
import DayBg from 'assets/images/svg/day-background.svg';
import NightBg from 'assets/images/svg/night-background.svg';
import DayIcon from 'assets/images/svg/day-icon.svg';
import NightIcon from 'assets/images/svg/night-icon.svg';
import CloseIcon from 'assets/images/svg/sidebar-close.svg';

/** Hooks */
import { useAppSelector } from 'hooks/UseAppSelector';
import { useAppDispatch } from 'hooks/UseAppDispatch';
import { useDeviceDetect } from 'hooks/UseDeviceDetect';

/** Components */
import Tab from 'components/Tab/Tab';
import Toggle from 'components/Toggle/Toggle';

/** Types */
import { IFlattenedItem } from 'features/Sidebar/types';

/** Store */
import {
  selectIsCountsRequestTriggered,
  selectIsSidebarVisible,
  selectThemeIsDark,
  setAdminDesignCounts,
  setDesignerDesignCounts,
  setIsCountsRequestTriggered,
  setSidebarVisibility,
  setThemeIsDark,
} from 'store/slices/Sidebar.slice';

/** Outseta */
import { useAuth } from 'outseta/AuthProvider';

/** Constants */
import { ROUTE } from 'constants/Routes';
import { ENDPOINTS } from 'constants/EndPoints';

/** Utilities */
import { flattenResponse } from 'utilities/functions';

/** Api */
import { getRequest } from 'api/apiClient';

/** Styles */
import * as S from './Sidebar.styled';

const Sidebar: FC = () => {
  const { isMobile } = useDeviceDetect();
  const dispatch = useAppDispatch();
  const { user, isUserDesigner, isUserDefault, isUserAdmin } = useAuth();

  const isDarkTheme = useAppSelector(selectThemeIsDark);
  const isVisible = useAppSelector(selectIsSidebarVisible);
  const isCountsRequestTriggered = useAppSelector(
    selectIsCountsRequestTriggered
  );
  const [sidebarItems, setSidebarItems] = useState<IFlattenedItem[]>();

  const isTabShownToUser = (tab: IFlattenedItem): boolean =>
    (!!isUserDesigner && tab.isForDesigners) ||
    (!!isUserDefault && tab.isForUsers) ||
    (!!isUserAdmin && !tab.isForDesigners && !tab.isForUsers);

  const getSidebarItems = async (): Promise<void> => {
    const response = await getRequest(ENDPOINTS.SIDE_MENU_ITEMS);
    setSidebarItems(flattenResponse(response.data.data));
  };

  const toggleTheme = (): void => {
    dispatch(setThemeIsDark(!isDarkTheme));
  };

  const getCounts = async (): Promise<void> => {
    if (isUserAdmin || isUserDesigner) {
      const approvedResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_APPROVED_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.APPROVED_DESIGNS_COUNT
        }`
      );
      const disapprovedResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_DISAPPROVED_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.DISAPPROVED_DESIGNS_COUNT
        }`
      );
      const pendingResponse = await getRequest(
        `${
          isUserDesigner
            ? ENDPOINTS.MY_PENDING_DESIGNS_COUNT(user.Uid)
            : ENDPOINTS.PENDING_DESIGNS_COUNT
        }`
      );
      const archivedResponse = isUserAdmin
        ? await getRequest(ENDPOINTS.ARCHIVED_DESIGNS_COUNT)
        : {};

      const counts = {
        approvedDesignsCount: approvedResponse.data.data,
        disapprovedDesignsCount: disapprovedResponse.data.data,
        pendingDesignsCount: pendingResponse.data.data,
        ...(isUserAdmin
          ? {
              archivedDesignsCount: archivedResponse.data.data,
            }
          : {}),
      };

      isUserAdmin && dispatch(setAdminDesignCounts(counts));
      isUserDesigner && dispatch(setDesignerDesignCounts(counts));
    }
    dispatch(setIsCountsRequestTriggered(false));
  };

  useEffect(() => {
    void getSidebarItems();
  }, []);

  const topData =
    sidebarItems?.filter(
      (dt) => isTabShownToUser(dt) && dt.category === 'top'
    ) ?? [];

  const footerData =
    sidebarItems?.filter(
      (dt) => isTabShownToUser(dt) && dt.category === 'footer'
    ) ?? [];

  useEffect(() => {
    dispatch(setSidebarVisibility(!!user && !isMobile));
  }, [isMobile, user]);

  useEffect(() => {
    dispatch(setIsCountsRequestTriggered(true));
  }, [isUserDesigner, isUserAdmin]);

  useEffect(() => {
    if (isCountsRequestTriggered) {
      void getCounts();
    }
  }, [isCountsRequestTriggered]);

  return (
    <S.Wrapper $isVisible={isVisible}>
      <S.LogoWrapper to={ROUTE.ROOT}>
        <S.Logo src={isDarkTheme ? LogoImage : DarkLogoImage} alt={'logo'} />
      </S.LogoWrapper>
      {(!isMobile || isVisible) && !!user && (
        <S.CloseButton
          $isSideBarVisible={isVisible}
          onClick={() => dispatch(setSidebarVisibility(!isVisible))}
          src={CloseIcon}
          alt={'close icon'}
        />
      )}
      <S.TabsWrapper>
        {topData &&
          topData?.length > 0 &&
          topData.map((tab, i) => (
            <Tab key={i} tab={tab} isTabShownToUser={isTabShownToUser} />
          ))}
      </S.TabsWrapper>
      <S.Footer>
        {footerData.map((tab, i) => (
          <Tab key={i} tab={tab} isTabShownToUser={isTabShownToUser} />
        ))}
        <S.ToggleContainer>
          <Toggle
            background={isDarkTheme ? NightBg : DayBg}
            isActive={isDarkTheme}
            knobBackground={isDarkTheme ? NightIcon : DayIcon}
            onClick={toggleTheme}
          />
        </S.ToggleContainer>
      </S.Footer>
    </S.Wrapper>
  );
};

export default Sidebar;
