/** Dependencies */
import React, { FC } from 'react';

/** Store */
import { ModalContentConfig } from 'store/slices/Modal.slice';

/** Components */
import { ApproveModalContent } from 'components/ApproveModalContent';
import { CopyLimitModalContent } from 'components/CopyLimitModalContent';
import { RemovalModalContent } from 'components/RemovalModalContent';
import { MessageModalContent } from 'components/MessageModalContent';

interface IProps {
  content: ModalContentConfig | null;
}

const ModalContentRenderer: FC<IProps> = ({ content }) => {
  if (!content) return null;

  const components = {
    Approve: ApproveModalContent,
    CopyLimit: CopyLimitModalContent,
    Remove: RemovalModalContent,
    Message: MessageModalContent,
  };

  const Component = Object.getOwnPropertyDescriptor(
    components,
    content.type
  )?.value;

  if (!Component) {
    console.warn(`Unknown modal content type: ${content.type}`);
    return null;
  }

  return <Component {...content.props} />;
};

export default ModalContentRenderer;
