/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Types */
import { ICard } from 'components/Card/types';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';
import { useDesignApprove } from 'hooks/UseDesignApprove';

const Pending: FC = () => {
  const [action, setAction] = useState<{ id: string; type: string } | null>(
    null
  );

  const noContentTexts = {
    title: 'Pending',
    description: 'There is currently no pending designs in the queue.',
    acknowledgment: '',
  };

  const {
    designs,
    isPageLoading,
    likes,
    handleLikeClick,
    getLikes,
    getDesigns,
  } = useDesigns({
    endpoint: ENDPOINTS.PENDING_DESIGNS,
  });

  const handleFinish = (): void => {
    setAction(null);
  };

  const { handleDesignApprove } = useDesignApprove({
    id: action?.id ?? '',
    type: action?.type ?? '',
    getDesigns,
    getLikes,
    onFinish: handleFinish,
  });

  const handleButtonClick = (card: ICard, type: string): void => {
    setAction({ id: card.documentId, type });
  };

  useEffect(() => {
    if (action?.id) {
      void handleDesignApprove();
    }
  }, [action?.id, handleDesignApprove]);

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          isLiked={likes?.some((like) => like === card.id)}
          isAdminCard
          isEditButtonShown
          onLike={handleLikeClick}
          onApprove={() => handleButtonClick(card, 'approve')}
          onDisapprove={() => handleButtonClick(card, 'disapprove')}
        />
      ))}
    </Page>
  );
};

export default Pending;
