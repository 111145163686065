/** Dependencies */
import React, { FC, useEffect, useState } from 'react';

/** Features */
import Page from 'features/Page/Page';

/** Constants */
import { ENDPOINTS } from 'constants/EndPoints';

/** Components */
import Card from 'components/Card/Card';

/** Hooks */
import { useDesigns } from 'hooks/UseDesigns';

/** Api */
import { useDesignApprove } from 'hooks/UseDesignApprove';

const Disapproved: FC = () => {
  const [action, setAction] = useState<{ id: string; type: string } | null>(
    null
  );

  const noContentTexts = {
    title: 'Disapproved',
    description: 'There is currently no disapproved designs.',
    acknowledgment: '',
  };

  const {
    designs,
    isPageLoading,
    likes,
    handleLikeClick,
    getLikes,
    getDesigns,
  } = useDesigns({
    endpoint: ENDPOINTS.DISAPPROVED_DESIGNS,
  });

  const handleFinish = (): void => {
    setAction(null);
  };

  const { handleDesignApprove } = useDesignApprove({
    id: action?.id ?? '',
    type: action?.type ?? '',
    getDesigns,
    getLikes,
    onFinish: handleFinish,
  });

  useEffect(() => {
    if (action?.id) {
      void handleDesignApprove();
    }
  }, [action?.id, handleDesignApprove]);

  return (
    <Page
      isContentShown={!!designs?.length && designs?.length > 0}
      noContentTexts={noContentTexts}
      isLoading={isPageLoading}
    >
      {designs?.map((card, i) => (
        <Card
          key={i}
          id={card.id}
          documentId={card.documentId}
          title={card.subjectLine}
          image={card.image.url}
          message={card.message}
          figmaObject={card.figmaObject}
          designerUser={card.designerUser}
          likeCount={undefined}
          isLiked={likes?.some((like) => like === card.id)}
          isAdminCard
          isEditButtonShown
          onLike={handleLikeClick}
          onApprove={() => setAction({ id: card.documentId, type: 'approve' })}
          onSuspend={() => setAction({ id: card.documentId, type: 'suspend' })}
        />
      ))}
    </Page>
  );
};

export default Disapproved;
