import styled, { css } from 'styled-components';
import { BreakPoint } from 'constants/BreakPoint';

const PageWrapper = styled.div<{ $columnCount: number }>`
  ${({ theme: { convertVW }, $columnCount }) => css`
    position: relative;
    display: grid;
    grid-template-columns: repeat(${$columnCount}, 1fr);
    gap: ${convertVW(24)};
    padding: ${convertVW(32)} ${convertVW(40)};

    @media only screen and (max-width: ${BreakPoint.Mobile}px) {
      grid-template-columns: repeat(1, 1fr);
      padding: ${convertVW(32)} ${convertVW(20)} ${convertVW(32)};
    }
  `}
`;

export { PageWrapper as Wrapper };
